import React from 'react';
import { Link } from 'react-router-dom';
import Logo from '../images/Logo.png';
import KnowYourselfConnectImage from '../images/KnowYourselfConnect.jpeg';
import '../styles/template.css';

const ConnectPage = () => {
  return (
    <div className="container">
      <header>
        <div className="logo-container">
          <img src={Logo} alt="Logo" className="logo" />
        </div>
        <h1 style={{ textAlign: 'center' }}>Know Yourself Connect</h1>
        <h2 style={{ textAlign: 'center' }}>Coming Soon…</h2>
        <nav>
          <ul style={{ display: 'flex', justifyContent: 'center' }}>
            <li><Link to="/">Home</Link></li>
            <li><Link to="/about">About</Link></li>
            <li><Link to="/connect">Connect</Link></li>
            <li><Link to="/contact">Contact Us</Link></li>
          </ul>
        </nav>
      </header>
      <main>
        <section style={{ textAlign: 'center' }}>
          <h2>Unlock Premium Features with Know Yourself Connect</h2>
          <img
            src={KnowYourselfConnectImage}
            alt="Know Yourself Connect"
            style={{
              width: '100%',
              maxWidth: '800px',
              height: 'auto',
              marginBottom: '20px',
              '@media (max-width: 767px)': {
                maxWidth: '400px',
              },
            }}
          />
          <p>
            Elevate your self-discovery journey with Know Yourself Connect.
          </p>
          <h3>Meetups</h3>
          <p>
            Create and join meetups tailored to your interests. Plan gatherings, discover like-minded individuals, and expand your social circle.
          </p>
          <h3>Events</h3>
          <p>
            Experience personalized event recommendations based on your unique intelligence type. Submit your weekly calendar, and let our app curate a customized agenda of events that align with your schedule and preferences. Discover new opportunities for growth and engagement that resonate with your individual strengths and interests.
          </p>
          <p>
            Upgrade to Know Yourself Connect and transform your self-discovery into a journey of connection!
          </p>
        </section>
      </main>
      <footer style={{ textAlign: 'center' }}>
        <p>&copy; 2024 Know Yourself LLC | All rights reserved.</p>
      </footer>
    </div>
  );
};


export default ConnectPage;
